body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-family: "微软雅黑";
    border: none;
}

.login {
    margin: 50px auto;
    text-align: center;
    font-size: 25px;
    padding-top: 120px;
    color: #4191f5;
}

.btn a {
    display: block;
    border-radius: 16px;
    width: 220px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 5px auto 20px auto;
    font-size: 16px;
    cursor: pointer;
}
.btn a:hover{
    box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
    transform: scale(1.1);
    transition-duration: 0.6s;
}
.btn .get {
    color: #4191f5;
    background: #fafafa;
    box-shadow: 0 5px 20px rgb(65 145 245 / 20%);
}
.btn .send {
    background: #4191f5;
    color: #fff;
    box-shadow: 0 5px 20px rgb(65 145 245 / 30%);
}

.queue {
    border: 1px solid #E5E5E5;
    height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 10px;
    padding: 0 3px 3px;
    width: calc(100% - 8px);
}